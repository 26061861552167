import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0030 from '../../../components/molecules/columnDetailInner0030'
import UsefulFunctionLayout010 from '../../../components/molecules/usefulFunctionLayout010'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0030 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '試用期間中に残業は出来るの？残業代など解説！',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="試用期間中に残業することに！残業代は支払われますか？"
        description="実際に業務に従事した期間を通して適性を判断するのが試用期間です。残業は可能ですが習熟度が低いため、時間外労働が発生する可能性が高くなるので注意が必要です。"
        ogUrl="https://kintaicloud.jp/column/details/C0030/"
        ogType="article"
        ogTitle="試用期間中に残業することに！残業代は支払われますか？"
        ogDescription="実際に業務に従事した期間を通して適性を判断するのが試用期間です。残業は可能ですが習熟度が低いため、時間外労働が発生する可能性が高くなるので注意が必要です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0030.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0030 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout010 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0030

export const pageQuery = graphql`
  query C0030 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
