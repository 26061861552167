import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0030 = () => (
  <Wrapper>
    <ColumnH1 label="試用期間中に残業することに！残業代は支払われますか？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0030.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        本採用を行う前に<span>試用期間</span>
        を設けることは一般的に用いられている労務管理手法ですが、試用期間中に残業を命じても問題ないかという疑問が生じます。試用期間前後を通じて労働基準法上の労働者であることは間違いありませんが、留意すべき部分があります。
        <br />
        今回は、試用期間についての解説に加え、試用期間中の残業について解説します。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">試用期間とは</a>
          </li>
          <li>
            <a href="#toc2">残業とは</a>
          </li>
          <li>
            <a href="#toc3">試用期間中の残業の留意点</a>
          </li>
          <li>
            <a href="#toc4">固定残業代を採用している場合の留意点</a>
          </li>
          <li>
            <a href="#toc5">深夜労働の留意点</a>
          </li>
          <li>
            <a href="#toc6">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>試用期間とは</h1>
      </div>
      <p>
        試用期間とは、法的には<span>解約権留保付労働契約</span>
        と呼ばれ、使用者側に解約権（本採用拒否）が留保された労働契約となります。しかし、試用期間であっても労働契約自体は成立しており、無制限に本採用拒否が認められるというわけではありません。
        <br />
        本採用拒否が認められるのは社会通念上相当な場合（例えば明らかな協調性不足）に限られます。そして、本採用拒否する場合がある旨は
        <span>就業規則に明記</span>
        してく必要があります。この部分が抜けていると本採用拒否する根拠がなく、労働契約が既に成立している前提条件を覆すことはできませんので、会社が不利な立場に立たされることがあります。
        <br />
        <br />
        また、本採用拒否は、その後の教育でカバーできる程度であれば認められません。そもそも企業という組織は一定の人材育成をしながら事業を営むことが一般的であり、教育でカバーできる程度の協調力不足であるにも関わらず本採用拒否する場合、違法とされる場合があります。
        <br />
        <br />
        尚、本採用との違いとして、本採用は試用期間を満了した状態で、通常の労働契約関係となります。本採用拒否の場合、引き続き試用期間を延長して様子を見ることや、雇用契約を解消するというフェーズに移行します。
        <br />
        試用期間が一般的となった背景として、面接や筆記試験のみではその人の適性を見極めることは難しく、実際に業務に従事した期間を通して適性を判断する必要性があるということです。一般的に試用期間は3か月～6か月の間で、
        <span>就業規則に規定を設けることで延長も可能</span>
        ですが、実際に延長する際には合理的な理由が必要です。
      </p>
      <div id="toc2">
        <h1>残業とは</h1>
      </div>
      <p>
        まず、労働時間の定義から確認します。労働時間とは、
        <span>使用者の指揮命令下に置かれた時間</span>
        とされ、明示の指示はもちろん黙示の指示により業務に従事した時間も労働時間と解されます。判断が難しい部分として、黙示の指示とは例えば口頭で明確に業務を指示されたわけではないものの、残業をしなければ到底期限までに業務が終えられないことが明らかな場合や、その状況を使用者が黙認している場合とされます。
        <br />
        使用者としては明示していないのだから残業としては認めないと判断することがありますが、法的には労働時間と解さることが少なくありません。
      </p>
      <Introduction001 />
      <div id="toc3">
        <h1>試用期間中の残業の留意点</h1>
      </div>
      <p>
        試用期間中であっても、労働基準法上の労働時間であることには変わりありませんので、残業が発生する余地はあります。留意点としては、多くの場合、試用期間中はベテラン社員と比べて業務に対する習熟度も高いとは言えず、少ない業務量であるにも関わらず長い時間をかけなければ業務を完遂できない可能性が高いということです。当たり前と言えば当たり前の事実ですが、使用者の想定よりも長時間労働が発生する可能性が高いということです。
        <br />
        <br />
        各事業場では時間外労働を命じる前には<span>36協定</span>
        を締結し、所轄労働基準監督署へ届け出をしていますが、協定した時間を超えて時間外労働を命じることは違法となります。使用者としては、「36協定での締結した時間を超えてまで業務を命じたつもりはない」と主張しても黙示の指示により労働時間とされる場合があり、その場合は労働時間であることには変わりありませんので、ベテラン社員以上に試用期間中労働者の労働時間管理は慎重に行うべきです。
        <br />
        <br />
        尚、残業は、原則として<span>月45時間、年間360時間が上限</span>
        となり、特別な事情がある場合であっても、休日労働と合わせて単月100時間未満、2～6か月の複数月平均80時間以下、年間720時間以下、月45時間を超えることができるのは年6回まででなければ違法となります。
      </p>
      <div id="toc4">
        <h1>固定残業代を採用している場合の留意点</h1>
      </div>
      <p>
        試用期間の前後を通じて固定残業代を採用している場合は更に留意すべき点があります。固定残業代とは、予め
        <span>定められた残業時間分</span>
        の「残業代」を支給することですが、予め定められた時間に達しない場合でも支払いが必要となり、その時間を超えて働いた場合には追加で残業代を支払う必要がある制度です。まずは、固定残業代は何時間分に相当するのかと基本給とは別にいくらを支払うのかを明確化する必要があります。そして、例えば20時間分の固定残業代として運用しているところ、25時間の残業が発生した際には5時間分を残業代として追加支給しなければなりません。
      </p>
      <div id="toc5">
        <h1>深夜労働の留意点</h1>
      </div>
      <p>
        残業とは時間の長さを指します。労働基準法上、1日8時間、週40時間を超えて働かせた分は残業代として割増賃金を支払うことが最低基準となります。また、深夜とは22時から翌朝5時の特定の時間帯を指します。よって、勤務終了後から引き続き深夜帯まで残業を行った場合には、通常の残業代に深夜割増分も付加して支払わなければ違法となります。もちろん試用期間の前後を通して解釈は同じです。
        <br />
        <br />
        そもそも慢性的な深夜労働が発生しているような状態では、長時間労働が常態化していると言え、本採用を行う以前に労働者側から自主退職が申し出られる可能性もあります。
      </p>
      <div id="toc6">
        <h1 className="read">最後に</h1>
      </div>
      <p>
        試用期間中であっても労働基準法上の労働者にあたることから、労働基準法で定める労働者保護の対象になること、試用期間中であっても残業を命じることは可能である反面、業務への習熟度が高い時期ではないことから、具体的な指示がない場合、使用者の想定を超えた時間外労働が発生する可能性が高いという点をおさえておく必要があります。
        <br />
        特に、試用期間中で退職となった場合は教育に要した時間も無駄となり、再度の従業員募集を行う必要があることから、重要な点と言えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0030
